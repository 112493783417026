<template>
  <div>
    <div class="container">
      <div class="upload-facture-validate ">
        <router-link
          class="block-icon-show-facture mr-2"
          to="/facture-libre"
          title="Interface facture libre"
        >
          <font-awesome-icon icon="arrow-left" class="mt-1"
        /></router-link>
        <div
          v-if="checkPermission('FLTFP')"
          class="block-icon-show-facture mr-2"
          @click="uploadFacture"
          title="Télecharger Facture"
        >
          <font-awesome-icon icon="cloud-download-alt" class="mt-1" />
        </div>

        <router-link
          class="block-icon-show-facture mr-2"
          to="/add-facture"
          title="Nouvelle Facture"
          v-if="checkPermission('FLCNF')"
        >
          <font-awesome-icon icon="file-medical" class="mt-1"
        /></router-link>
        <router-link
          class="block-icon-show-facture"
          :to="'/duplicate-facture/' + $route.params.id"
          title="Dupliquer Facture"
          v-if="checkPermission('FLDF')"
        >
          <font-awesome-icon icon="clone" class="mt-1" />
        </router-link>
      </div>
      <iframe
        class="iframe-position"
        :src="src + '#toolbar=0'"
        height="900"
        width="800"
      ></iframe>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'afficheFacture',
  data() {
    return {
      src: null
    };
  },
  computed: {
    ...mapGetters(['checkPermission'])
  },
  methods: {
    ...mapActions(['displayFact', 'downloadPDF']),
    uploadFacture() {
      let ids = [];
      ids.push({
        id: this.$route.params.id
      });
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', ids[0]['id']);
      this.downloadPDF({ id: ids[0].id });
    }
  },
  async mounted() {
    const response = await this.displayFact(this.$route.params.id);
    if (response.success === true) {
      this.src = response.response;
    }
  }
};
</script>
<style lang="scss" scoped>
.block-icon-show-facture {
  background-color: #f1edfb !important;
  box-shadow: none !important;
  padding: 6.5px;
  font-size: 15px;
  font-weight: 600;
  color: #704ad1;
  border-radius: 4px;
  padding: 0px 5px;
  border: 1px solid #704ad1;
  box-shadow: 5px 6px 8px #9799d67a;
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
  min-width: 29px !important;
  z-index: 1;
  svg {
    color: #704ad1;
    overflow: hidden;
    vertical-align: middle;
    transform: rotateZ(0deg);
    height: 20px;
    width: 20px;
  }
}
.iframe-position {
  display: flex;
  margin-left: 15%;
}

.upload-facture-validate {
  display: flex;
  margin-left: 47%;
  margin-bottom: 1%;
}
</style>
