<template>
  <div class="pl-25">
    <div class="container">
      <router-link to="/facture-libre" class="custom-link">
        <span>
          Retourner vers Factures Libres
        </span>
      </router-link>
    </div>
    <iframe
      v-if="checkPermission('FLAF')"
      class="iframe-position"
      :src="src + '#toolbar=0'"
    ></iframe>
  </div>
</template>

<script>
import domain from '@/environment';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      src: domain + '/api/libre/generate-pdf?id=' + this.$route?.params?.id
    };
  },
  computed: { ...mapGetters(['checkPermission']) }
};
</script>

<style lang="scss" scoped>
.custom-link {
  color: #4d4bac;
  font-weight: bold;
}
.pl-25 {
  padding-left: 25%;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
